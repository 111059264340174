<template>
  <div class="documents">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12 pt-5  pt-md-0">
            <h2 class="py-5 text-center">AML/CTF Policy</h2>
            <p class="pb-3">
              Prochange UAB is regulated by the Lithuanian Financial Crime Investigation Service that is operating under The Ministry of the Interior of the Republic of Lithuania.
            </p>
            <p class="pb-3">
              The Rules are prepared in accordance with the Law on the Prevention of Money Laundering and Terrorist Financing of the Republic of Lithuania and other applicable legal acts of the Republic of Lithuania.
            </p>
            <p class="pb-3">
              The Rules describe how the Company will organize and ensure the adequate anti-money laundering and counter terrorism financing procedures. The implementation of the Rules will ensure that the name, reputation and financial integrity of the Company, whilst ensuring compliance with all necessary laws and regulations.
            </p>
            <h3 class="pb-3">
              I. Customer Identification
            </h3>
<p class="pb-3">
  The Company takes all necessary, proportionate measures to identify its customer and to verify the identity of the Customer and Customer’s Beneficial Owners. The Company takes measures and determine identity of the Customer or his representative. Every new customer shall go through the KYC process according to the Rules. Their risk profile is classified as low, medium, or high risk for ML/TF based on criteria set out in the Rules.
</p>
<p class="pb-3">
  Prochange UAB will verify:
</p>
            <ul>
              <li class="pb-3">Customer’s identity.</li>
              <li class="pb-3">Customer’s residency (registration place, if Customer is a legal entity);</li>
              <li class="pb-3">Nature of business activity.</li>
              <li class="pb-3">Actual location of business activities.</li>
              <li class="pb-3">Customer’s (legal entity’s) ownership and complexity of control structure.</li>
              <li class="pb-3">Nationality of Beneficial Owner.</li>
              <li class="pb-3">Volume and nature of transactions carried out by the Customer.</li>
              <li class="pb-3">Social / financial status.</li>
            </ul>
<h3 class="pb-3">
  II. Monitoring
</h3>
            <p class="pb-3">
              The Company gathers information about the Customer risk profile and expected behaviour when the Customer applies for Company’s services. The gathered information provides information about the expected behaviour of the Customer and a baseline for identification of suspicious activity.
            </p>
<p class="pb-3">
  When suspicious activity is identified, or the Customer otherwise has a suspicious behaviour or pattern that indicates a risk for money laundering, the Company shall seek to investigate the behaviour and to provide a rationale for the identified suspicious behaviour by asking the Customer for additional information to rule out inappropriate behaviour or attempt to launder money.
</p>
<h3 class="pb-3">
  III. Geography Risk
</h3>
            <p class="pb-3">
              Prochange UAB screen all customers and entities, CEO and UBO against PEP and Sanctions lists.
            </p>
<p class="pb-3">
  Negative risk impact factors:
</p>
            <p class="pb-3">1.1.a. If a country is subject of EU sanctions.</p>
            <p class="pb-3">1.1.b. The country is one of the countries on the list of high risk and other monitored jurisdictions published by and the Financial Action Task Force (<a
                href="http://www.fatf-gafi.org/countries/">http://www.fatf-gafi.org/countries/</a>)</p>
            <p class="pb-3">1.1.c. The country on the list of third countries with strategic deficiencies in their anti-money laundering and counter-terrorist financing frameworks published by the European Commission</p>
            <p class="pb-3">1.1.d. Has a weak or non-existent AML/CTF legislation.</p>
            <p class="pb-3">1.1.e. Has a high Corruption Perception Index.</p>
<h3 class="pb-3">
  IV. Reporting
</h3>
<p class="pb-3">
  The Responsible Employee of the Company shall submit a written report at least once a year to the CEO of the Company about the execution of functions related to prevention of money laundering and / or terrorist financing. The Company shall, upon assessment of the threat posed by money laundering and/or terrorist financing, decide on the appropriateness of forwarding a report on a suspicious monetary operation or transaction to the Financial Crime Investigation Service of the Republic of Lithuania.
</p>
<h3 class="pb-3">
  V. Records
</h3>
<p class="pb-3">
  Records of the results of the investigation of complex or unusually large transactions and unusual patterns of transactions shall be stored for 5 years in paper or electronic form. The storage period may be extended for a maximum period of 2 years, when there is a motivated reason provided by the competent authority. The documents and information referred to shall be stored, regardless of whether the monetary transactions or transactions are domestic or international; business relations with the Customer are ongoing or have expired. Moreover, the documents and information referred to shall be stored in such a way as to enable the recovery of specific monetary transactions or transactions, and to provide the information contained therein, if necessary, to the FCIS or other competent authorities.
</p>
<h3 class="pb-3">
  VI. Data Protection
</h3>
            <p class="pb-3">a. The exchange of information is permitted only to prevent money laundering and terrorist financing.</p>
            <p class="pb-3">b. Exceptions to the transmission of the information provided are not valid if a separate decision of the European Commission has been adopted.</p>
            <p class="pb-3">c. When exchanging information with entities registered in third countries and providing personal data to these entities, the provision of personal data must comply with the requirements of the laws protecting personal data.</p>
            <p class="pb-3">d. The Company or its employees are not liable for the breach of contractual obligations or damage to the Customer if this is due to a monetary operation or a suspension of a transaction.</p>
            <p class="pb-3">e. Employees of the Company who are willing to notify the FCIS of suspicious monetary transactions or transactions executed by the Customer shall not be held liable.</p>

<h3 class="pb-3">
  VII. Summary
</h3>
<p class="pb-3">
  These Rules may be amended, supplemented, or revoked by decision of the CEO of the Company.
</p>
            <p class="pb-3">
              These Rules shall be reviewed periodically (at least once a year) or upon any substantial events related to the operation of the Company or changes to applicable laws and shall be amended accordingly to ensure proper implementation of the money laundering and terrorist financing prevention measures, its effectiveness and relevancy. The Responsible Employee is responsible for the timely revision of the Rules and the preparation and submission of draft amendments to the CEO.
            </p>
<p class="pb-3">
  The Company conducts special training for the employees of the Company on issues related to the prevention of money and terrorist financing, as well as the proper implementation of these Rules
</p>





          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AMLAndCTF",
  mounted() {
    document.querySelector('body').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
</script>
<style scoped>
li {
  margin-left: 30px;
}

</style>
